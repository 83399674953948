import React from "react";

const { instafeed } = require("instafeed-node-js");

// Fetch latest posts from Instagram
const instagram = () => {
  instafeed({
    access_token: process.env.GATSBY_INSTAGRAM_ACCESS_TOKEN,
    requestedCount: 3
  })


    .then(response => {
      let instagram_posts = response.data;

      var instaObj = [];
      for (var insta of instagram_posts) {
        let instaImageURL = "";

        if (
          insta.media_type === "IMAGE" ||
          insta.media_type === "CAROUSEL_ALBUM"
        ) {
          instaImageURL = insta.media_url;
        } else {
          instaImageURL = insta.thumbnail_url;
        }
        let instaURL = insta.permalink;

        //let instaHTML = `<div class="instagram_card col"><a href="${instaURL}" target="_blank"><div class="in" style="background-image:url('${instaImageURL}');"></div></a></div>`;

        let instaHTML = `<div class="instagram_card col"><a href="${instaURL}" target="_blank"><img class="in" src="${instaImageURL}" alt="Instagram Tank Design image" /></a></div>`;

        instaObj.push(instaHTML);
      }
      document.getElementById("tankInstafeed").innerHTML = instaObj;
    })
    .catch(error => {
      //document.getElementById("tankInstafeed").innerHTML = error;
    });
  return (
    <section className="tank-instagram l-vspace">
      <div className="tank-instagram__title l-content l-content--fixed">
        <h4 className="t-h6">
          Follow{" "}
          <a
            href="https://www.instagram.com/tank_design/"
            target="_blank"
            rel="noopener noreferrer"
          >
            @tank_design
          </a>
        </h4>
      </div>
      <div className="l-content">
        <div id="tankInstafeed" className="row"></div>
      </div>
    </section>
  );
};

export default instagram;
